<template>
  <event-detail :eventType="3" />
</template>
<script>
import EventDetail from '@/components/EventDetail.vue'

export default {
  name: 'banner',
  components: {
    EventDetail
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {}

}
</script>

<style>
</style>